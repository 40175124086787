<template>

  <v-dialog
    v-model="dialog"
    eager
    scrollable
    persistent max-width="600px"
    @keydown.esc="dialog = isDirty()">

    <template v-slot:activator="{ on: dialog }">

      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            fab
            x-small
            v-on="{ ...dialog, ...tooltip }">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>New Terminal User</span>
      </v-tooltip>
    </template>

    <v-form v-model="isValid" ref="form" @submit.prevent="save">

      <v-card>

        <v-card-title class="headline">
          New Terminal User

          <v-spacer />

          <v-switch
            v-if="$vuetify.breakpoint.smAndUp"
            v-model="active"
            inset
            dark
            :label="active ? 'Active' : 'Suspended'" />
        </v-card-title>

        <v-divider />

        <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-switch
                  v-if="$vuetify.breakpoint.xsOnly"
                  v-model="active"
                  inset
                  :label="active ? 'Active' : 'Suspended'" />
              </v-col>

              <v-col cols="12">
                <v-text-field
                  label="Channel"
                  outlined
                  disabled
                  :value="channel.name"
                  />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Network"
                  outlined
                  disabled
                  :value="network.name"
                  />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Terminal"
                  outlined
                  disabled
                  :value="terminal.name"
                  />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  hint="Required"
                  persistent-hint
                  label="Name"
                  outlined
                  clearable
                  inputmode="text"
                  v-model="user.account_name"
                  :rules="[rules.required, rules.min(2)]"
                  :background-color="isFieldDirty('name') ? '#faf9f5' : ''"
                  />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  hint="Required"
                  persistent-hint
                  label="Username"
                  outlined
                  clearable
                  v-model="user.username"
                  :rules="[rules.min(4)]"
                  />
              </v-col>
              <v-col cols="12">
                <!--<v-text-field-->
                  <!--label="Password"-->
                  <!--outlined-->
                  <!--password-->
                  <!--clearable-->
                  <!--v-model="user.password"-->
                  <!--:rules="[rules.min(8)]"-->
                  <!--/>-->
                <v-text-field
                  hint="Required"
                  persistent-hint
                  label="Password"
                  outlined
                  password
                  clearable
                  :counter="(user.password || '').length > 0"
                  v-model="user.password"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPassword ? 'text' : 'password'"
                  @click:append="showPassword = !showPassword"
                  :rules="[rules.min(8)]"
                  :background-color="isFieldDirty('password') ? '#faf9f5' : ''"
                  />
              </v-col>
            </v-row>

            <v-alert v-if="error" type="error">
              {{ error }}
            </v-alert>

        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer v-if="!$vuetify.breakpoint.xsOnly" />

          <v-btn text width="72" @click="dialog = false;">Cancel</v-btn>

          <v-spacer v-if="$vuetify.breakpoint.xsOnly" />

          <v-btn v-if="saving" width="72" text disabled>
            <v-progress-circular indeterminate color="primary" />
          </v-btn>

          <v-btn v-else text color="primary" type="submit" :disabled="!canSave">Create</v-btn>
        </v-card-actions>

      </v-card>

    </v-form>

  </v-dialog>

</template>

<script>
import { mapGetters, mapActions } from 'vuex'
//import { updatedDiff } from 'deep-object-diff'
import _ from 'lodash'

export default {
  props: ['terminalId'],

  data() {
    return {
      dialog: false,
      isValid: false,
      saving: false,
      error: undefined,

      showPassword: false,

      channel: {},
      network: {},
      terminal: {},

      user: {},
      active: true,

      rules: {
        emailOrEmpty: v => (v || '').trim().length == 0 || /.+@.+\..+/.test(v) || 'E-mail must be valid',
        required: value => !!value || 'Required',
        min(len) { return v => v && v.length >= len || `Minimum ${len} characters` },
        phoneOrEmpty(len) { return v => {
          v = (v?.replace(/\D/g,'') || '').trim()
          return v.length == 0 || (v && v.length >= len) || `Minimum ${len} characters`
        } },
      },
    }
  },

  computed: {
    ...mapGetters('Channel', ['channelById', 'terminalById', 'networkById']),
    ...mapGetters('User', ['isUserActive']),

    canSave() { return this.isDirty() && this.isValid },
  },

  methods: {
    ...mapActions('Channel', ['addChannel']),
    ...mapActions('User', ['addUser']),

    initData() {
      this.saving = false
      this.error = undefined

      this.terminal = this.terminalById(this.terminalId)
      this.network  = this.networkById(this.terminal.network_id)
      this.channel = this.channelById(this.network.channel_id)
      //this.terminal = {...this.terminal, network_id: this.network.id, active: true }

      this.user = {}
      this.active = true

      this.$refs.form.resetValidation()
    },

    isFieldDirty(name) {
      return this.user[name]?.trim() != ''
    },

    isDirty() {
      const user    = _.pickBy(this.user, _.identity)

      if (!this.active) return true

      const isDirty = Object.keys(user).length > 0

      return isDirty
    },

    async save() {

      if (!this.canSave) return

      this.saving = true
      this.error = undefined

      this.addUser( {...this.user, isCashSpotUser: true, cashSpotTerminal: this.terminalId, disabled: !this.active} )

      .then(addedUser => {
        this.saving = false
        this.dialog = false
        this.$router.push({name: 'terminal-user', params: { channel_id: this.channel.id, network_id: this.network.id, terminal_id: this.terminal.id, account_id: addedUser.account_id }})
      })

      .catch(error => {
        this.error = error?.response?.data ?? error
        this.saving = false
      })
    }
  },

  watch: {

    dialog: {
      handler() { if (this.dialog) this.initData() },
      immediate: true,
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep {
  .v-card__title {
    background-color: var(--v-primary-base);
    color: var(--v-headerText-base);
  }
  .v-input--is-label-active label {
    font-weight: bold !important;
    color: var(--v-primary-base);
  }

  .v-card__title.headline {
    .v-input--switch label {
      color: white;
    }
  }

  /*
    Fix for autocomplete placeholder only showing on focus
    https://github.com/vuetifyjs/vuetify/issues/11553
  */

  .v-autocomplete:not(.v-input--is-focused).v-select--chips input { max-height: 25px !important; }
  /*

  .v-input--is-label-active label {
    color: white !important;
    font-weight: bold;
  }
  */
}
</style>

