<template>

  <v-dialog
    v-model="dialog"
    eager
    scrollable
    persistent max-width="600px"
    @keydown.esc="dialog = isDirty()"
  >

  <template v-slot:activator="{ on: dialog }">
    <v-tooltip bottom>
      <template v-slot:activator="{ on: tooltip }">
        <v-btn
          fab
          x-small
          v-on="{ ...dialog, ...tooltip }">
          <v-icon>mdi-circle-edit-outline</v-icon>
        </v-btn>
      </template>
      <span>Change Terminal</span>
    </v-tooltip>
  </template>

  <v-form ref="form" v-model="isValid" @submit.prevent="save">

    <v-card>

      <v-card-title class="headline">
        Change Terminal

        <v-spacer />

        <v-switch
          v-if="$vuetify.breakpoint.smAndUp"
          v-model="terminal.active"
          inset
          dark
          :label="terminal.active ? 'Active' : 'Suspended'" />
      </v-card-title>

      <v-divider />

      <v-card-text>

        <v-container>

            <v-row>
              <v-col cols="12">
                <v-switch
                  v-if="$vuetify.breakpoint.xsOnly"
                  v-model="terminal.active"
                  inset
                  :label="terminal.active ? 'Active' : 'Suspended'" />
              </v-col>

              <v-col cols="12">
                <v-text-field
                  label="Channel"
                  outlined
                  disabled
                  :value="channel.name"
                  />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Network"
                  outlined
                  disabled
                  :value="network.name"
                  />
              </v-col>
              <v-col cols="12" class="pb-0">
                <v-text-field
                  label="Store Name"
                  outlined
                  clearable
                  inputmode="text"
                  v-model="terminal.name"
                  :rules="[rules.required, rules.min(2)]"
                  :background-color="isFieldDirty('name') ? '#faf9f5' : ''"
                  />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Address"
                  inputmode="text"
                  outlined
                  clearable
                  v-model="terminal.address"
                  :background-color="isFieldDirty('address') ? '#faf9f5' : ''"
                  />
              </v-col>
              <v-col cols="8">
                <v-text-field
                  label="City"
                  inputmode="text"
                  outlined
                  clearable
                  v-model="terminal.city"
                  :background-color="isFieldDirty('city') ? '#faf9f5' : ''"
                  />
              </v-col>
              <v-col cols="4">
                <v-text-field
                  label="Zip"
                  inputmode="numeric"
                  outlined
                  clearable
                  v-model="terminal.zip"
                  :background-color="isFieldDirty('zip') ? '#faf9f5' : ''"
                  />
              </v-col>
              <v-col cols="6">
                <v-autocomplete
                  v-model="terminal.country"
                  :items="countries"
                  item-text="name"
                  item-value="id"
                  label="Country"
                  outlined
                  :background-color="isFieldDirty('country') ? '#faf9f5' : ''">
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                Terminal Fees
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <v-text-field
                  label="Load Fee"
                  inputmode="numeric"
                  outlined
                  clearable
                  v-model="terminal.load_fee"
                  :background-color="isFieldDirty('load_fee') ? '#faf9f5' : ''"
                  />
              </v-col>
              <v-col cols="4">
                <v-text-field
                  label="Cashback Fee"
                  inputmode="numeric"
                  outlined
                  clearable
                  v-model="terminal.cashback_fee"
                  :background-color="isFieldDirty('cashback_fee') ? '#faf9f5' : ''"
                  />
              </v-col>
            </v-row>

          <v-alert v-if="error" type="error">
            {{ error }}
          </v-alert>

        </v-container>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer v-if="!$vuetify.breakpoint.xsOnly" />

        <v-btn text @click="dialog = false;">Cancel</v-btn>

        <v-spacer v-if="$vuetify.breakpoint.xsOnly" />

        <v-btn color="primary" type="submit" :disabled="!canSave" :loading="saving">Save</v-btn>
      </v-card-actions>

    </v-card>

  </v-form>

</v-dialog>

</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ct from 'countries-and-timezones'
import _ from 'lodash'

export default {
  props: ['terminalId'],

  data() {
    return {
      dialog: false,
      saving: false,
      isValid: false,
      error: undefined,

      countries: Object.values(ct.getAllCountries()),

      channel: {},
      network: {},
      terminal: {},

      rules: {
        emailOrEmpty: v => (v || '').trim().length == 0 || /.+@.+\..+/.test(v) || 'E-mail must be valid',
        required: value => !!value || 'Required',
        min(len) { return v => v && v.length >= len || `Minimum ${len} characters` },
        phoneOrEmpty(len) { return v => {
          v = (v?.replace(/\D/g,'') || '').trim()
          return v.length == 0 || (v && v.length >= len) || `Minimum ${len} characters`
        } },
      },
    }
  },

  computed: {
    ...mapGetters('Channel', ['channelById', 'networkById', 'terminalById']),

    canSave() { return this.isDirty() && this.isValid },
  },

  methods: {
    ...mapActions('Channel', ['saveTerminal']),

    initData() {
      this.saving = false
      this.error = undefined

      this.terminal = _.cloneDeep(this.terminalById(this.terminalId))
      this.network = _.cloneDeep(this.networkById(this.terminal.network_id))
      this.channel = _.cloneDeep(this.channelById(this.network.channel_id))
    },

    isFieldDirty(name) {
      const terminalId = this.terminalId

      let originalTerminal = this.terminalById(terminalId)
      if (!originalTerminal) {
        console.error('Could not find original terminal for comparison', {terminalId, name});
      }

      let original = originalTerminal?.[name]?.toString().trim() ?? ''
      let modified = this.terminal[name]?.toString().trim() ?? ''

      return original != modified
    },

    isDirty() {
      const keysMonitored = ['active', 'name', 'address', 'city', 'zip', 'country', 'load_fee', 'cashback_fee'].sort()
      const isDirty = keysMonitored.some(key => this.isFieldDirty(key))

      return isDirty
    },

    save() {
      if (!this.canSave) return

      this.saving = true

      this.saveTerminal(this.terminal)

      .then(() => { //updatedNetwork => {
        this.saving = false
        this.dialog = false
        //this.$router.push({name: 'network', params: { channel_id: this.channelId, network_id: addedNetwork.id }})
      })

      .catch(error => {
        this.error = error?.response?.data ?? error?.message
        this.saving = false
      })
    }
  },

  watch: {
    dialog: {
      handler() { if (this.dialog) this.initData() },
      immediate: true,
    },
  }
}
</script>

<style scoped lang="scss">
::v-deep {
  .v-card__title {
    background-color: var(--v-primary-base);
    color: var(--v-headerText-base);
  }
  .v-input--is-label-active label {
    font-weight: bold !important;
    color: var(--v-primary-base);
  }
  .v-card__title.headline {
    .v-input--switch label {
      color: white;
    }
  }
}
</style>
