<template>

  <v-container
    fluid
    class="ma-0 pa-0"
    >

    <Confirm ref="confirm" />

    <div
      class="ml-sm-4"
      v-if="this.$vuetify.breakpoint.mdAndUp || this.$route.name == 'terminal'"
      :style="$vuetify.breakpoint.mdAndUp
        ? 'display: grid; grid-template-columns: 300px auto; overflow-x: hidden;'
        : 'position: absolute; left: 0; bottom: 0; top: 0; right: 0; display: grid; grid-template-columns: auto;'"
      >

      <div style="grid-column: 1;">

        <div
          :style="$vuetify.breakpoint.mdAndUp
            ? 'width: 300px; position: sticky; overflow-y: scroll;'
            : ''
            ">

          <v-list-item class="overline pt-0 mt-0 pl-1">
            Terminal
            <v-spacer />

            <div class="pl-2">
              <ChangeTerminalDialog :terminalId="terminalId" />

              <v-tooltip bottom v-if="terminalUsers.length == 0">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    x-small
                    class="ml-3"
                    v-bind="attrs"
                    v-on="on"
                    :loading="deleting"
                    @click="deleteTerminal">
                    <v-icon>mdi-delete-outline</v-icon>
                  </v-btn>
                </template>
                <span>Delete Terminal</span>
              </v-tooltip>
            </div>
          </v-list-item>

          <v-card
            outlined
            class="pa-0 ma-0 mt-2 flex-grow-1"
            style="">

            <v-card-title class="py-2">
              {{ terminal.name }}
              <v-spacer />
              <v-icon color="red">
                <template v-if="(channel.inactive || network.inactive) && terminal.inactive">mdi-minus-circle-multiple</template>
                <template v-else-if="network.inactive">mdi-minus-circle-multiple-outline</template>
                <template v-else-if="terminal.inactive">mdi-minus-circle-outline</template>
              </v-icon>
            </v-card-title>

            <v-card-text class="pb-3">
              <template v-if="terminal.address">
                {{ terminal.address }}
              </template>
              <template v-else-if="!terminal.city || !terminal.country || !terminal.zip">
                <em>No Address</em>
              </template>

              <template v-if="terminal.city && terminal.country">
                <br />
                {{ terminal.city }}, {{ countryName }}
              </template>

              <template v-else-if="terminal.city">
                <br />
                {{ terminal.city }}
              </template>

              <template v-else-if="terminal.country">
                <br />
                {{ countryName }}
              </template>

              <template v-if="terminal.zip">
                <br />
                {{ terminal.zip }}
              </template>
            </v-card-text>

            <v-card-text class="pt-0">
              <v-simple-table dense style="color: grey;">
              <tbody>
                <tr>
                  <td>Load Fee</td>
                  <td>{{ formatCurrency(terminal.load_fee, network.currency) }}</td>
                </tr>
                <tr>
                  <td>Cashback Fee</td>
                  <td>{{ formatCurrency(terminal.cashback_fee, network.currency) }}</td>
                </tr>
              </tbody>
              </v-simple-table>

              <div
                v-if="isViewedInUserContext"
                class="mt-4 d-flex justify-end">
                <v-btn
                  link
                  :to="{name: 'terminal', params: { channel_id: channel.id, network_id: network.id, terminal_id: terminal.id }}">
                  View in Network
                </v-btn>
              </div>
            </v-card-text>

          </v-card>

          <v-list
            v-if="isViewedInNetworkContext"
            v-model="terminalUserSelectedIndex">

            <v-list-item class="overline pt-0 mt-0 pl-1">
              Terminal Users
              <v-spacer />

              <NewTerminalUserDialog :terminalId="terminal.id" />
            </v-list-item>

            <v-list-item class="pa-0">
              <v-text-field
                dense
                hide-details
                outlined
                clearable
                label="Name"
                v-model="terminalUserFilter"
                @click:clear="clearTerminalUserFilter"
                class="mt-2" />
            </v-list-item>

            <v-list-item
              v-for="item in filteredTerminalUserList"
              :key="item.id"
              :to="{name: 'terminal-user', params: { account_id: item.account_id }}"
              link>

              <v-list-item-content>
                <v-list-item-title>
                  {{ item.account_name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ identitiesByAccountId(item.account_id).map(i => i.username).join(',') }}
                </v-list-item-subtitle>

              </v-list-item-content>

              <v-list-item-icon>
                <v-icon :style="{visibility: isUserActive(item.account_id) ? 'hidden' : 'visible'}" color="red">mdi-minus-circle-outline</v-icon>

                <v-icon>mdi-chevron-right</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list>

        </div>
      </div>

      <div
        class="mt-sm-1 ml-sm-1"
        v-if="$vuetify.breakpoint.mdAndUp"
        style="grid-column: 2; overflow: scroll;"
        >
        <router-view />
      </div>
    </div>

    <router-view v-else />

  </v-container>

</template>

<script>

import { mapGetters, mapActions } from 'vuex'
import PhoneNumber from 'awesome-phonenumber'
import ChangeTerminalDialog from '@/components/ChangeTerminalDialog.vue'
import NewTerminalUserDialog from '@/components/NewTerminalUserDialog.vue'
import ct from 'countries-and-timezones'
import Confirm from '@/components/Confirm.vue'

export default {
  data() {
    return {
      deleting: false,

      channel: {},
      network: {},

      terminalId: undefined,
      terminal: {},

      terminalUserFilter: '',
      terminalUserSelectedIndex: undefined,
      terminalUserSelected: 0,
      filteredTerminalUserList: [],
    }
  },

  metaInfo() {
    return {
      title: 'Terminal',
    }
  },

  computed: {
    ...mapGetters('Channel', ['channelById', 'networkById', 'terminalById', 'terminals']),
    ...mapGetters('User', ['users', 'usersByTerminalId', 'identitiesByAccountId', 'isUserActive']),

    countryName() {
      return ct.getCountry(this.terminal.country)?.name
    },

    terminalUsers() {
      return this.usersByTerminalId(this.terminalId) ?? []
    },

    isViewedInNetworkContext() { return !!this.$route.params.network_id },
    isViewedInUserContext() { return !this.isViewedInNetworkContext },
  },

  components: {
    ChangeTerminalDialog,
    NewTerminalUserDialog,
    Confirm,
  },

  methods: {
    ...mapActions('Channel', [ 'getTerminalList', 'removeTerminal' ]),
    ...mapActions('User', [ 'getUsers' ]),

    formatPhone: (phone) => phone ? PhoneNumber(phone.replace(/\D/gi, ''), 'US').getNumber('national') : undefined,

    clearTerminalUserFilter() {
      this.terminalUserFilter = ''
    },

    filterTerminalUserList() {
      const filter = this.terminalUserFilter?.toLowerCase() ?? ''
      const terminalUsers = this.terminalUsers

      const filteredTerminalUserList = filter.length == 0
        ? terminalUsers
        : terminalUsers.filter(terminalUser =>
            terminalUser.account_name?.toLowerCase().includes(filter)
          )

      this.filteredTerminalUserList = [...filteredTerminalUserList]
    },

    deleteTerminal() {

      this.$refs.confirm.open(
        'Delete Terminal',
        'Deletion is permanent. Are you sure?',
      )

      .then(confirmed => {
        if (!confirmed) throw 0;

        this.deleting = true

        return this.removeTerminal(this.terminal.id)
      })

      .then(() => {
        return this.$router.push({name: 'network', params: { channel_id: this.channel.id, network_id: this.network.id  }})
      })

      .catch(error => {
        if (!error) return

        this.error = error?.response?.data ?? error?.message

        console.error(this.error)
      })

      .finally(() => {
        this.deleting = false
      })
    },
  },

  watch: {

    '$route.params.terminal_id': {
      handler(newValue) {
        this.terminalId    = newValue
        this.terminal      = this.terminalById(newValue) ?? {}
        this.network       = this.networkById(this.terminal.network_id)
        this.channel       = this.channelById(this.network.channel_id)
        //this.terminalUsers = this.usersByTerminalId(newValue)

        this.getUsers()
      },
      immediate: true,
    },

    terminals: {
      handler() {
        this.terminal = this.terminalById(this.terminalId) ?? {}
      }
    },

    usersByTerminalId: {
      handler() {
        //debugger
        this.filterTerminalUserList()
      },
      immediate: true,
    },

    terminalUserFilter: {
      handler() { this.filterTerminalUserList() },
      immediate: true,
    },
  },
}
</script>

<style lang="scss" scoped>
/*::v-deep {
  .v-badge--inline .v-badge__badge {
    background-color: inherit !important;
    width: 0.7em;

    &:after {
    border-color: gray;
  }
  }
}
*/
</style>


